import React from 'react';
import Img from 'gatsby-image';
import Button from '@material-ui/core/Button';
import SectionHeader from './section-header';
import Grid from '@material-ui/core/Grid';

import { Link, useStaticQuery } from 'gatsby';

import { makeStyles } from '@material-ui/styles';
import apple from '../images/badge-download-on-the-app-store.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridGap: theme.spacing(1),
    gridTemplateColumns: '100%',
    maxWidth: 'unset',
    justifyItems: 'center',
    [theme.breakpoints.up('md')]: {
      maxWidth: 350,
      gridTemplateColumns: '1fr 1fr',
      justifyItems: 'left',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      justifyItems: 'left',
    },
  },
  link: {
    textDecoration: 'none',
  },
  google: {
    width: 180,
    height: 'auto',
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      marginTop: -11,
      marginLeft: -12,
      marginRight: -12,
    },
  },
  apple: {
    height: 'auto',
    width: 144,
  },
  tryHero: {
    boxShadow: 'none',
    color: 'white',
    backgroundColor: 'black',
    height: 45,
    width: 144,
  },
}));

const CallToAction = ({ hero }) => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query CallToAction {
      google: file(relativePath: { eq: "google-play-badge.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <div className={classes.root}>
      <a
        className={classes.link}
        href="https://itunes.apple.com/app/id1343344602"
      >
        <img
          src={apple}
          className={classes.apple}
          alt="outlines of shapes and confetti in the background "
        />
      </a>
      <a
        className={classes.link}
        href="https://play.google.com/store/apps/details?id=io.pushback"
      >
        <Img
          className={classes.google}
          fluid={data.google.childImageSharp.fluid}
          alt="outlines of shapes and confetti in the background "
        />
      </a>
      <a className={classes.link} href="https://app.pushback.io/tryitout">
        <Button variant="contained" className={classes.tryHero}>
          Try it on web
        </Button>
      </a>
    </div>
  );
};

export default CallToAction;
