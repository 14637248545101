import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
    [theme.breakpoints.up('md')]: {
      paddingTop: '5.5rem',
      paddingBottom: '5.5rem',
    },
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '40% 48%',
    },
    gridColumnGap: '12%',
    alignItems: 'center',
  },
  gridInverse: {
    gridTemplateColumns: '100%',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '48% 40%',
    },
  },
}));

export default function Section({ children, inverse, className }) {
  const classes = useStyles();
  return (
    <section className={classNames(classes.section, className)}>
      <div className={classNames(classes.grid, inverse && classes.gridInverse)}>
        {children}
      </div>
    </section>
  );
}
