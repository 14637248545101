import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
//import Button from '@material-ui/core/Button'

import { BREAKPOINTS, COLORS } from '../styles/constants';
import Code from './Code';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
    [theme.breakpoints.up('md')]: {
      paddingTop: '5.5rem',
      paddingBottom: '5.5rem',
    },
  },
  textMuted: {
    padding: 15,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    alignItems: 'flex-start',
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr',
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
    gridGap: theme.spacing(4),
  },
  title: {
    textAlign: 'center',
  },
  box: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const findExample = `
find program.log -mmin +20 -execdir pushback "The system is down!"
`;

const cronExample = `
0 0 1 * * pushback "Pay Rent"
0 22 * * 1-5 pushback "Exercise"
`;

const progressExample = `
ddrescuelog -t /Volumes/etc/part1-ddrescue.log | pushback "$(</dev/stdin)"
`;

const demandExample = `
eval $(pushback "Run command") | pushback "$(</dev/stdin)"
`;

const Examples = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="lg" component="div" className={classes.container}>
        <Typography variant="h1" className={classes.title}>
          Examples
        </Typography>

        <div className={classes.grid}>
          <div className={classes.box}>
            <Typography variant="body1" className={classes.textMuted}>
              Send a message if a process stops logging
            </Typography>
            <div style={{ flex: 1 }} />
            <Code code={findExample} language="bash" />
          </div>
          <div className={classes.box}>
            <Typography variant="body1" className={classes.textMuted}>
              Track your habits with cron jobs
            </Typography>
            <div style={{ flex: 1 }} />
            <Code code={cronExample} language="bash" />
          </div>
          <div className={classes.box}>
            <Typography variant="body1" className={classes.textMuted}>
              Get progress updates
            </Typography>
            <div style={{ flex: 1 }} />
            <Code code={progressExample} language="bash" />
          </div>
          <div className={classes.box}>
            <Typography variant="body1" className={classes.textMuted}>
              Execute arbitrary commands (not recommended)
            </Typography>
            <div style={{ flex: 1 }} />
            <Code code={demandExample} language="bash" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Examples;
