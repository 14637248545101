import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import bash from '../images/bash.svg';
import phone from '../images/smartphone.svg';
import { makeStyles } from '@material-ui/styles';
import Poster from './Poster';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
    [theme.breakpoints.up('md')]: {
      paddingTop: '5.5rem',
      paddingBottom: '5.5rem',
    },
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
    gridGap: theme.spacing(4),
  },
}));

const Content = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="lg" component="div" className={classes.root}>
      <Typography variant="h1" className={classes.title}>
        Multiple ways to integrate
      </Typography>
      <div className={classes.grid}>
        <Poster
          title="Code"
          descLink="Synchronous mode"
          description=" blocks code execution until a reply is received"
          link="/docs/sync"
        >
          <img src={bash} style={{ maxWidth: 300 }} alt="terminal" />
        </Poster>
        <Poster
          title="Webhooks"
          description="Notify your server anytime a message or reply is sent"
          link="/docs/webhooks"
        >
          <pre style={{ textAlign: 'left' }}>
            <code className="code-text">{`messageSent {
  title
  body
}
replySent {
  text
  message {
    id
  }
}`}</code>
          </pre>
        </Poster>
        <Poster
          title="Apps"
          description="Easily send messages from the app and view complete message history"
          margin={0}
        >
          <img
            src={phone}
            style={{ maxHeight: 300 }}
            alt="outlines of shapes and confetti in the background "
          />
        </Poster>
      </div>
    </Container>
  );
};

export default Content;
