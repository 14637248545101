import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Img from 'gatsby-image';
import { useStaticQuery } from 'gatsby';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import Hero from '../components/hero';
import Content from '../components/content';
import Items from '../components/Items';
import Examples from '../components/Examples';
import Notifications from '../components/notifications';
import CallToAction from '../components/cta';
import Terminal from '../components/Terminal';
import Section from '../components/Section';
import line1 from '../images/line_1.svg';
import line2 from '../images/line_2.svg';

const useStyles = makeStyles((theme) => ({
  lineWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  line1: {
    position: 'absolute',
    zIndex: 30,
    marginTop: '20vh',
    width: '30vw',
    maxWidth: 400,
    visibility: 'hidden',
    [theme.breakpoints.up('md')]: {
      visibility: 'visible',
    },
  },
  line2Wrap: {
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
  },
  line2: {
    position: 'absolute',
    zIndex: 30,
    width: '45vw',
    maxWidth: 600,
  },
  title: {
    marginBottom: theme.spacing(6),
  },
  body1: {
    marginTop: theme.spacing(2),
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    alignContent: 'center',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1.2fr .8fr',
    },
    gridGap: theme.spacing(4),
  },
  section: {
    marginTop: '10vh',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  lightblub: {
    color: theme.palette.primary.main,
    fontSize: '50vw',
    [theme.breakpoints.up('sm')]: {
      fontSize: '25vw',
    },
    transform: 'rotate(180deg)',
  },
  blubWrap: {
    textAlign: 'center',
  },
  red: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  website: {
    marginTop: theme.spacing(2),
    borderRadius: 4,
    boxShadow: 'rgba(0, 0, 0, 0.4) 10px 10px 20px',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
    [theme.breakpoints.up('md')]: {
      paddingTop: '5.5rem',
      paddingBottom: '5.5rem',
    },
  },
}));
const IndexPage = () => {
  const [color, setColor] = React.useState('rgb(244,67,54)');
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query IndexPage {
      website: file(relativePath: { eq: "website.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title="Actionable Push Notifications API"
        keywords={['pushover', 'pushbullet', 'alternative', 'slack api']}
      />
      <Hidden smDown>
        <Container maxWidth="lg" className={classes.lineWrap}>
          <div className={classes.grid}>
            <div />
            <svg
              className={classes.line1}
              width="494"
              height="722"
              viewBox="0 0 494 722"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M288.96 6C258.185 6 -96.9999 68.5 36.9595 320.7C170.919 572.899 512.5 190.5 486 721.5"
                stroke={color === 'rgb(244,67,54)' ? 'black' : color}
                strokeWidth="6"
                strokeLinejoin="round"
                strokeDasharray="40 20"
              />
            </svg>
          </div>
        </Container>
      </Hidden>
      <Hero setColor={setColor} />
      <Container maxWidth="lg" component="div" className={classes.section}>
        <Section>
          <div>
            <Typography variant="h1" className={classes.title}>
              Hassle free setup
            </Typography>
            <Typography variant="body1" className={classes.body1}>
              Send messages and receive replies with one http request
            </Typography>
            <Typography variant="body1" className={classes.body1}>
              No registration required. Try it out and sign up later
            </Typography>
            <Typography variant="body1" className={classes.body1}>
              No firewall configuration or opening ports
            </Typography>
            <Typography variant="body1" className={classes.body1}>
              No need for your own server or app
            </Typography>
          </div>
          <Terminal />
        </Section>
        <Container maxWidth="lg" component="div" className={classes.lineWrap}>
          <svg
            width="911"
            className={classes.line2}
            height="251"
            viewBox="0 0 911 251"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M905 -7.39098e-06C905 151.025 820.357 193.568 633.723 146.417C447.088 99.2655 6.00001 91.8206 6.00001 251"
              stroke="black"
              stroke-width="6"
              stroke-linejoin="round"
              stroke={color === 'rgb(244,67,54)' ? 'black' : color}
              stroke-dasharray="40 20"
            />
          </svg>
        </Container>
        <Section inverse>
          <div className={classes.blubWrap}>
            <WbIncandescentIcon
              className={classes.lightblub}
              style={{ color }}
            />
          </div>
          <div>
            <Typography variant="h1" className={classes.title}>
              Iterate your ideas fast
            </Typography>
            <Typography variant="body1" className={classes.body1}>
              Code samples in bash, ruby, python, golang, bash, etc
            </Typography>
            <Typography variant="body1" className={classes.body1}>
              Further integration to your server with webhooks
            </Typography>
            <Typography variant="body1" className={classes.body1}>
              Available on all platforms -- iOS, Android, and web
            </Typography>
          </div>
        </Section>
      </Container>
      <div className={classes.red}>
        <Container maxWidth="lg" component="div">
          <Section>
            <div>
              <Typography variant="h1" className={classes.title}>
                Share access with others
              </Typography>
              <Typography variant="body1" className={classes.body1}>
                Once you have solidified your idea you can invite others with a
                link. They will be able to interact with the same notifications
                as you. Organize friends, family, or co-workers into seperate
                channels so that they only have limited access.
              </Typography>
            </div>
            <Img
              style={{ width: 600, maxWidth: '100%' }}
              className={classes.website}
              fluid={data.website.childImageSharp.fluid}
              alt="Pushback's channels organizing groups of ideas"
            />
          </Section>
        </Container>
      </div>
      <Items />
      <Notifications />
      <Content />
      <Examples />
      <Container maxWidth="lg" component="div">
        <div className={classes.bottom}>
          <Typography variant="h1" className={classes.title}>
            Try Pushback for free!
          </Typography>
          <CallToAction />
        </div>
      </Container>
    </Layout>
  );
};

export default IndexPage;
