import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import Typed from 'typed.js';

import CallToAction from '../components/cta';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 4vw',
    minHeight: '50vh',
    [theme.breakpoints.up('md')]: {
      minHeight: '60vh',
    },
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1.2fr .8fr',
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
    gridGap: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
  phone: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  buttonWrap: {
    zIndex: 303,
    position: 'absolute',
    width: 250,
    top: 107,
  },
  button: {
    backgroundColor: 'transparent',
    width: 250,
    height: 42,
    position: 'relative',
    zIndex: 10,
    textTransform: 'Capitalize',
  },
  notifTitle: {
    color: 'black',
    fontSize: 13,
    marginBottom: -1,
    marginLeft: 16,
  },
  notifSubtitle: {
    color: 'black',
    fontSize: 12,
    marginLeft: 16,
    marginBottom: 20,
  },
}));

export default function Hero({ setColor }) {
  const classes = useStyles();
  function handleAction1(event, newValue) {
    setColor('blue');
  }
  function handleAction2(event, newValue) {
    setColor('green');
  }
  const data = useStaticQuery(graphql`
    query Hero {
      phone: file(relativePath: { eq: "ios_blank@2x.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <div className={classes.root}>
      <Container maxWidth="lg" component="main" className={classes.container}>
        <div className={classes.grid}>
          <div>
            <Typography className={classes.title} variant="h1">
              Quickly connect actions to your scripts or code
            </Typography>
            <Typography className={classes.subtitle} variant="subtitle1">
              Send interactive push notifications via Pushback's simple API
            </Typography>
          </div>
          <div className={classes.phone}>
            <div className={classes.buttonWrap}>
              <Typography className={classes.notifTitle} variant="body1">
                <strong>Choose a color</strong>
              </Typography>
              <Typography className={classes.notifSubtitle} variant="body1">
                It will change the lightblub below!
              </Typography>
              <Button onClick={handleAction1} className={classes.button}>
                Blue
              </Button>
              <Button onClick={handleAction2} className={classes.button}>
                Green
              </Button>
            </div>
            <Img
              style={{ minWidth: 300, width: 300 }}
              fluid={data.phone.childImageSharp.fluid}
              alt="actionable notification on ios"
            />
          </div>
          <CallToAction hero />
        </div>
      </Container>
    </div>
  );
}
