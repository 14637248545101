import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  link: {
    color: theme.palette.primary.main,
  },
  box: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  desc: {
    marginTop: theme.spacing(2),
    minHeight: 50,
  },
}));

const Poster = ({ descLink, link, title, children, description }) => {
  const classes = useStyles();
  let content = (children) => (
    <div className={classes.box}>
      <Typography variant="h3">{title}</Typography>
      {children}
    </div>
  );

  return (
    <div className={classes.root}>
      {content(children)}
      <div style={{ flex: 1 }} />
      <div className={classes.desc}>
        <Typography variant="body2">
          {descLink && (
            <a href={link} className={classes.link}>
              {descLink}
            </a>
          )}
          {description}
        </Typography>
      </div>
    </div>
  );
};

export default Poster;
