import React, { useCallback, useState, useEffect, useRef } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { COLORS, BREAKPOINTS } from '../styles/constants';

import IphoneScreen from './image';
import IphoneAction1 from './action1';
import IphoneAction2 from './action2';
import IphoneMessage from './iphone_message';
import mockupFrame from '../images/mockup-frame.png';
import '../styles/arrow.css';
import Code from './Code';

const bashCode = `
#!/bin/bash
echo "waiting for user..."
ACTION=$(curl -s https://api.pushback.io/v1/send_sync \\
  -u <access token>: \\
  -d 'id=User_1' \\
  -d 'title=Choose a color' \\
  -d 'body=It will change the lightblub below!' \\
  -d "action1=Blue" \\
  -d "action2=Green")
echo "user action was: $ACTION"
`;

const pythonCode = `
import sys
import requests
print("waiting for user...")

data = {
  "id": 'User_1',
  "title": 'Choose a color',
  "body": "It will change the lightblub below!",
  "action1": 'Blue',
  "action2": 'Green',
}
r = requests.post("https://api.pushback.io/v1/send_sync",
        json=data,
        headers={"Authorization": "Bearer <at_token>"})
print("user action was: " + r.content)
`;

const rubyCode = `
require "net/http"
require "json"

puts "waiting for user..."
uri = URI('https://api.pushback.io/v1/send_sync')
req = Net::HTTP::Post.new uri,
                          'Content-Type' => 'application/json',
                          'Authorization' => 'Bearer <at_token>'
req.body = {
  id: 'User_1',
  title: 'Choose a color',
  body: "It will change the lightblub below!",
  action1: 'Blue',
  action2: 'Green',
}.to_json
res = Net::HTTP.start(uri.hostname, uri.port,
                      use_ssl: true) { |http| http.request(req) }
puts "user action was: #{res.body}"
`;

const goCode = `
package main

import (
	"bytes"
	"encoding/json"
	"fmt"
	"io/ioutil"
	"net/http"
	"os"
)

func main() {
	fmt.Println("waiting for user...")
	values := map[string]string{
		"id":      "User_1",
		"title":   "Choose a color",
		"body":    "It will change the lightbulb below!",
		"action1": "Blue",
		"action2": "Green",
	}
	jsonData, err := json.Marshal(values)
	checkErr(err)
	req, err := http.NewRequest("POST",
		"https://api.pushback.io/v1/send_sync",
		bytes.NewBuffer(jsonData))
	checkErr(err)
	req.Header.Add("Content-Type", "application/json")
	req.Header.Add("Authorization", "Bearer <at_token>")
	resp, err := http.DefaultClient.Do(req)
	checkErr(err)
	defer resp.Body.Close()
	body, err := ioutil.ReadAll(resp.Body)
	checkErr(err)
	fmt.Printf("user action was: %s", body)
}

func checkErr(err error) {
	if err != nil {
		panic(err)
	}
}
`;

const AntTabs = withStyles({
  root: {},
  indicator: {
    backgroundColor: 'transparent',
  },
  scrollButtons: {
    color: '#f9f9f8',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    backgroundColor: '#ada9a5',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    marginLeft: 5,
    '&:hover': {
      opacity: 1,
    },
    '&$selected': {
      color: '#42413c',
      backgroundColor: '#f9f9f8',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#42413c',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  buttonWrap: {
    position: 'absolute',
    width: 250,
    marginLeft: 17,
    top: 125,
  },
  button: {
    backgroundColor: 'transparent',
    width: 219,
    height: 37,
    position: 'relative',
    zIndex: 10,
  },
  demoContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  resetButton: {
    color: 'white',
  },
  terminalWrap: {
    zIndex: 301,
    width: '100%',
    marginBottom: 30,
    marginTop: 32,
    borderRadius: 10,
    boxShadow: 'rgba(0, 0, 0, 0.4) 10px 10px 20px',
    clear: 'both',
    display: 'block',
    maxHeight: 450,
    overflow: 'auto',
  },
  terminalTab: {
    color: 'black',
    fontFamily: 'Lucida Grande, sans-serif',
    fontSize: '0.75em',
    paddingTop: 10,
    textAlign: 'center',
    textShadow: 'rgba(255, 255, 255, 0.8) 0px 1px 0px',
    backgroundImage: 'linear-gradient(top, #56544c, #42413c)',
  },
  terminal: {
    minHeight: 150,
    padding: 10,
    color: '#f0f0f0',
    backgroundColor: 'hsl(0, 0%, 8%)',
    textShadow: '#000 0px 1px 0px',
    fontFamily: 'Consolas, Courier New, Courier',
    lineHeight: '1.40em',
    fontWeight: 500,
    textAlign: 'left',
    overflow: 'hidden',
  },
  title: {
    marginBottom: 10,
    [theme.breakpoints.up('md')]: {
      marginBottom: 40,
    },
    marginTop: 10,
    textAlign: 'left',
    color: 'white',
  },
}));

const Demo = ({ siteTitle }) => {
  const classes = useStyles();
  const el = useRef(null);
  const [waiting, setWaiting] = useState(false);
  const [action, setAction] = useState('');
  const [currentTab, setCurrentTab] = React.useState(0);

  function handleChange(event, newValue) {
    setCurrentTab(newValue);
  }

  const handleReset = useCallback(() => {
    setAction('');
    setCurrentTab(0);
  });

  return (
    <div className={classes.terminalWrap}>
      <div className={classes.terminalTab}>
        <AntTabs
          value={currentTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <AntTab label="bash" />
          <AntTab label="python" />
          <AntTab label="ruby" />
          <AntTab label="golang" />
        </AntTabs>
      </div>
      <div className={classes.terminal}>
        <TabPanel value={currentTab} index={0}>
          <Code code={bashCode} language="bash" />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <Code code={pythonCode} language="python" />
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          <Code code={rubyCode} language="ruby" />
        </TabPanel>
        <TabPanel value={currentTab} index={3}>
          <Code code={goCode} language="go" />
        </TabPanel>
      </div>
    </div>
  );
};

export default Demo;
