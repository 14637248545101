import React from 'react';
import Img from 'gatsby-image';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { Link, useStaticQuery } from 'gatsby';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    justifyContent: 'center',
    display: 'flex',
    padding: '20px 10px',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      padding: 30,
    },
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
    [theme.breakpoints.up('md')]: {
      paddingTop: '5.5rem',
      paddingBottom: '5.5rem',
    },
    color: 'white',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
    gridGap: theme.spacing(4),
  },
  title: {},
  link: {
    textDecoration: 'none',
    color: 'white',
  },
  img: {},
}));

const Notifications = ({ siteTitle }) => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query Notifications {
      ios: file(relativePath: { eq: "message_ios.png" }) {
        childImageSharp {
          fluid(maxHeight: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      android: file(relativePath: { eq: "message_android.png" }) {
        childImageSharp {
          fluid(maxHeight: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      web: file(relativePath: { eq: "message_web.png" }) {
        childImageSharp {
          fluid(maxHeight: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className={classes.root}>
      <Typography variant="h1" className={classes.title}>
        Actionable notifications
      </Typography>
      <div className={classes.grid}>
        <div className={classes.box}>
          <Typography variant="h3">Android</Typography>
          <Img
            style={{ width: 300 }}
            className={classes.img}
            fluid={data.android.childImageSharp.fluid}
            alt="reply to android notification"
          />
        </div>
        <div className={classes.box}>
          <Typography variant="h3">iOS</Typography>
          <Img
            style={{ width: 300 }}
            className={classes.img}
            fluid={data.ios.childImageSharp.fluid}
            alt="actionable ios notification"
          />
        </div>
        <div className={classes.box}>
          <Typography variant="h3">Web</Typography>
          <div className={classes.imgWrap}>
            <Img
              className={classes.img}
              style={{ width: 300 }}
              fluid={data.web.childImageSharp.fluid}
              alt="actionable web notification"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Notifications;
