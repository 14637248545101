import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { Helmet } from 'react-helmet';
import BuildIcon from '@material-ui/icons/Build';
import ShareIcon from '@material-ui/icons/Share';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';

import { BREAKPOINTS, COLORS } from '../styles/constants';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px 10px',
    [BREAKPOINTS.md]: {
      padding: '100px 30px',
    },
  },
  icon: {
    color: COLORS.pushback,
    fontSize: 30,
    width: 50,
    height: 50,
  },
  textMuted: {
    padding: 15,
  },
}));
const Items = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Helmet>
        <link
          href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
          rel="stylesheet"
          integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
          crossorigin="anonymous"
        />
      </Helmet>

      <Grid container style={{ textAlign: 'center' }}>
        <Grid item xs={12} md={6} lg={3}>
          <i
            style={{
              fontSize: 40,
              width: 50,
              height: 50,
              marginTop: 7,
              color: COLORS.pushback,
            }}
            className="fa fa-4x fa-diamond wow text-primary"
          />
          <h3>Quick Setup</h3>
          <p className={classes.textMuted}>
            Install the app and do <strong>one</strong> http request. That's it.
          </p>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <BuildIcon className={classes.icon} />
          <h3>Message Dashboard</h3>
          <p className={classes.textMuted}>
            See all your messages and replies in one place
          </p>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MobileFriendlyIcon className={classes.icon} />
          <h3>Hassle Free</h3>
          <p className={classes.textMuted}>
            No registration required. Try it out and sign up later.
          </p>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <ShareIcon className={classes.icon} />
          <h3>Multiple Users</h3>
          <p className={classes.textMuted}>
            Invite friends, family, and coworkers to receive messages with a
            link.
          </p>
        </Grid>
      </Grid>
    </div>
  );
};
export default Items;
